<ng-container>
  <front-content>
    <front-header
      [title]="'Not found'"
    />

    <front-section>
      Not found service
    </front-section>

  </front-content>
</ng-container>

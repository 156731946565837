<ng-container>
  <button
    (click)="onClick($event)"
  >
    @if (!label) {
      <ng-content/>
    } @else {
      {{ label }}
    }
  </button>
</ng-container>

import { Route } from '@angular/router';
import { FrontComponent } from '../../modules/front/components/front/front.component';
import HomeRoutes from './routes/home.routes';
import AboutRoutes from './routes/about.routes';
import RseRoutes from './routes/rse.routes';
import ContactRoutes from './routes/contact.routes';
import NewsRoutes from './routes/news.routes';
import ServicesRoutes from './routes/services.routes';
import SolutionsRoutes from './routes/solutions.routes';
import NotFoundRoutes from './routes/not-found.routes';

export default {
  path: '',
  component: FrontComponent,
  children: [
    HomeRoutes,
    AboutRoutes,
    RseRoutes,
    ContactRoutes,
    NewsRoutes,
    ServicesRoutes,
    SolutionsRoutes,
    NotFoundRoutes,
  ],
} as Route;

<ng-container>
  @if (solution) {
    <front-header
      [title]="solution.title"
    />

    <front-section>
      <div
        [innerHTML]="solution.description"
      >
      </div>
    </front-section>
  }
</ng-container>

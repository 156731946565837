import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { HtmlMetaService } from '@ckmk/angular';
import { getDevLastServices } from '../../../../../../utils/dev.utils';
import { environment } from '../../../../../../../environments/environment';
import { FrontHeaderComponent } from '../../../../components/front-header/front-header.component';
import { FrontSectionComponent } from '../../../../components/front-section/front-section.component';

@Component({
  selector: 'service-show',
  standalone: true,
  imports: [FrontHeaderComponent, FrontSectionComponent],
  templateUrl: './service-show.component.html',
  styleUrl: './service-show.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ServiceShowComponent implements OnInit, OnChanges {
  @Input({
    required: true,
    alias: 'category',
  })
  public categorySlug!: string;

  @Input({
    required: true,
    alias: 'service',
  })
  public serviceSlug!: string;

  protected service!: any;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _htmlService: HtmlMetaService,
  ) {}

  public ngOnInit() {
    this._initService();
  }

  public ngOnChanges({ categorySlug, serviceSlug }: SimpleChanges) {
    if (
      (categorySlug && !categorySlug.firstChange) ||
      (serviceSlug && !serviceSlug.firstChange)
    ) {
      this._initService();
    }
  }

  private _getService(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      resolve(
        getDevLastServices(40).find(
          (it) =>
            it.slug === this.serviceSlug &&
            it.category.slug === this.categorySlug,
        ),
      );
    });
  }

  private _initService(): void {
    this._getService().then((res) => {
      this.service = res;

      this._htmlService.setViewTitle(this.service.title, environment.SITE_NAME);
    });
  }
}

<ng-container>
  <front-content>
    <front-header
      [title]="'Actualités'"
    />

    <front-section>
      <div class="cards-container">
        @if (news && news.length > 0) {
          @for (item of news; track $index) {
            <new-home-card [item]="item"/>
          }
        }
      </div>
    </front-section>

  </front-content>
</ng-container>

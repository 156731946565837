<ng-container>
  <ng-content select=".outside-top"/>

  <div class="front-container">
    @if (title) {
      <headline [title]="title" [label]="title" [level]="getHeadlineLevel()" />
    }

    <div class="front-content">
      <ng-content/>
    </div>
  </div>

  <ng-content select=".outside-bottom"/>
</ng-container>

import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FrontContentComponent } from '../../../../components/front-content/front-content.component';
import { FrontSectionComponent } from '../../../../components/front-section/front-section.component';
import {
  getDevLastNews,
  getDevLastServices,
  getDevLastSolutions,
} from '../../../../../../utils/dev.utils';
import { CardsHomeContainerComponent } from '../../components/cards-home-container/cards-home-container.component';

@Component({
  selector: 'home',
  standalone: true,
  imports: [
    FrontContentComponent,
    FrontSectionComponent,
    CardsHomeContainerComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, OnChanges {
  protected lastNews: any[] = [];

  protected lastServices: any[] = [];

  protected lastSolutions: any[] = [];

  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {
    this.getLastSolutions().then((res) => (this.lastSolutions = res));

    this.getLastServices().then((res) => (this.lastServices = res));

    this.getLastNews().then((res) => (this.lastNews = res));
  }

  public ngOnChanges(changes: SimpleChanges): void {}

  protected getLastNews(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(getDevLastNews());
    });
  }

  protected getLastServices(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(getDevLastServices());
    });
  }

  protected getLastSolutions(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(getDevLastSolutions());
    });
  }
}

import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { PlatformIdService } from '@ckmk/angular';
import { SolutionHomeCardComponent } from '../../../solutions/components/solution-home-card/solution-home-card.component';
import { ServiceHomeCardComponent } from '../../../services/components/service-home-card/service-home-card.component';
import { NewHomeCardComponent } from '../../../news/components/new-home-card/new-home-card.component';

@Component({
  selector: 'cards-home-container',
  standalone: true,
  imports: [
    SolutionHomeCardComponent,
    ServiceHomeCardComponent,
    NewHomeCardComponent,
  ],
  templateUrl: './cards-home-container.component.html',
  styleUrl: './cards-home-container.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CardsHomeContainerComponent implements OnInit, OnChanges {
  @Input({
    required: true,
  })
  public type!: 'solutions' | 'services' | 'news';

  @Input({
    required: true,
  })
  public items!: any[];

  public constructor(
    private readonly _self: ElementRef,
    private readonly _platformIdService: PlatformIdService,
  ) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges): void {}

  protected isMobileScreen(): boolean {
    return (
      this._platformIdService.isBrowser() &&
      document.body.getBoundingClientRect().width <= 768
    );
  }
}

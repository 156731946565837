<ng-container>
  <front-content>
    <front-header
      [title]="'About us'"
    />

    <front-section>
      <div class="picture-text-container">
        <img
          src="/img/about_us_team.jpg"
          alt="About us"
          class="picture"
        />

        <div class="text-content">
          <headline
            [level]="4"
            [label]="'À propos de l\'équipe'"
          />

          <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium aut commodi consequuntur culpa
            cupiditate debitis doloribus magni molestiae mollitia, necessitatibus nihil officia quam quia recusandae
            repudiandae tenetur, totam vero voluptate!
          </div>
          <div>Ab alias aliquid commodi consequatur delectus dicta ea est fugiat harum illum, incidunt ipsum iste itaque
            magni minima neque obcaecati omnis pariatur placeat reiciendis rem repellat repellendus tempora veritatis
            voluptatem.
          </div>
          <div>Adipisci architecto, cupiditate ducimus enim error exercitationem explicabo incidunt ipsa libero nemo
            numquam optio praesentium, quibusdam tempora tempore tenetur voluptates. Alias impedit in modi nihil nobis
            porro repellendus suscipit veritatis.
          </div>
        </div>
      </div>
    </front-section>

    <front-section class="bg-gray-50">
      <div class="picture-text-container reverse">
        <img
          src="/img/about_us_goal.jpg"
          alt="About us"
          class="picture"
        />

        <div class="text-content">
          <headline
            [level]="4"
            [label]="'Nos objectifs'"
          />

          <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium aut commodi consequuntur culpa
            cupiditate debitis doloribus magni molestiae mollitia, necessitatibus nihil officia quam quia recusandae
            repudiandae tenetur, totam vero voluptate!
          </div>
          <div>Ab alias aliquid commodi consequatur delectus dicta ea est fugiat harum illum, incidunt ipsum iste itaque
            magni minima neque obcaecati omnis pariatur placeat reiciendis rem repellat repellendus tempora veritatis
            voluptatem.
          </div>
          <div>Adipisci architecto, cupiditate ducimus enim error exercitationem explicabo incidunt ipsa libero nemo
            numquam optio praesentium, quibusdam tempora tempore tenetur voluptates. Alias impedit in modi nihil nobis
            porro repellendus suscipit veritatis.
          </div>
        </div>
      </div>
    </front-section>

  </front-content>
</ng-container>

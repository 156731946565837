<ng-container>
  @if (category) {
    <front-content>
      <front-header
        [title]="category.title"
      />

      <front-section>
        <div class="cards-container">
          @if (category.solutions && category.solutions.length > 0) {
            @for (solution of category.solutions; track $index) {
              <solution-home-card [item]="solution"/>
            }
          }
        </div>
      </front-section>
    </front-content>
  }
</ng-container>

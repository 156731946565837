<ng-container>
  @if (category) {
    <front-content>
      <front-header
        [title]="category.title"
      />

      <front-section>
        <div class="cards-container">
          @if (category.services && category.services.length > 0) {
            @for (service of category.services; track $index) {
              <service-home-card [item]="service"/>
            }
          }
        </div>
      </front-section>
    </front-content>
  }
</ng-container>

/**
 * Adds elements to the list defined by the count parameter
 * @param cb
 * @param count
 */
export const pushLoop = (
  cb: (index: number, position: number) => any,
  count: number = 3,
): any[] => {
  const list: any[] = [];

  for (let i = 0; i < count; i++) {
    list.push(cb(i, i + 1));
  }

  return list;
};

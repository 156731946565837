<ng-container>
  <!-- The view header -->
  <div
    #view_header
    [class]="{
      'hidden': view_header.innerHTML.length < 1,
    }"
  >
    <ng-content select="front-header"/>
  </div>

  <!-- The default header -->
  @if (view_header.innerHTML.length < 1) {
    <div
      class="default-front-header"
    >
      <div
        class="bg-front-home"
        [style.background-image]="'url(\'/img/home-header.jpg\')'"
      ></div>

      <div class="home-message front-container">
        <div class="home-message-content">
          <headline [label]="'Umbrella-CIE'"/>

          <p class="font-bold">
            VOS AMBITIONS, UNE RÉALITÉ
          </p>

          <div class="home-message-buttons">
            <btn
              [label]="'Rejoins-nous'"
            />
          </div>
        </div>
      </div>
    </div>
  }

  <!-- The front content -->
  <ng-content/>
</ng-container>

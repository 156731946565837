<ng-container>
  <front-content>
    <front-section [title]="'Dernières actualités'">
      @if (lastNews.length) {
        <cards-home-container
          [type]="'news'"
          [items]="lastNews"
        />
      }
    </front-section>

    <front-section [title]="'Nos services'">
      @if (lastServices.length) {
        <cards-home-container
          [type]="'services'"
          [items]="lastServices"
        />
      }

      <div class="gray-headband outside-bottom">

      </div>
    </front-section>

    <front-section [title]="'Nos solutions'">
      @if (lastSolutions.length) {
        <cards-home-container
          [type]="'solutions'"
          [items]="lastSolutions"
        />
      }
    </front-section>

  </front-content>
</ng-container>

<ng-container>
  @if (newItem) {
    <front-header
      [title]="newItem.title"
    />

    <front-section>
      <div
        [innerHTML]="newItem.description"
      >
      </div>
    </front-section>
  }
</ng-container>

import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  getDevLastSolutions,
  getDevSolutionCategories,
} from '../../../../../../utils/dev.utils';
import { FrontContentComponent } from '../../../../components/front-content/front-content.component';
import { FrontHeaderComponent } from '../../../../components/front-header/front-header.component';
import { FrontSectionComponent } from '../../../../components/front-section/front-section.component';
import { SolutionHomeCardComponent } from '../../components/solution-home-card/solution-home-card.component';
import { HtmlMetaService } from '@ckmk/angular';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-solution-category',
  standalone: true,
  imports: [
    FrontContentComponent,
    FrontHeaderComponent,
    FrontSectionComponent,
    SolutionHomeCardComponent,
  ],
  templateUrl: './solution-category.component.html',
  styleUrl: './solution-category.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SolutionCategoryComponent implements OnInit, OnChanges {
  @Input({
    required: true,
    alias: 'category',
  })
  public categorySlug!: string;

  protected category!: any;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _htmlService: HtmlMetaService,
  ) {}

  public ngOnInit() {
    this._initCategory();
  }

  public ngOnChanges({ categorySlug }: SimpleChanges): void {
    if (categorySlug && !categorySlug.firstChange) {
      this._initCategory();
    }
  }

  protected getLastSolutions(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const category = getDevSolutionCategories().find(
        (c) => c.slug === this.categorySlug,
      );
      category.solutions = getDevLastSolutions(20).filter(
        (item) => item.category.slug === this.categorySlug,
      );

      resolve(category);
    });
  }

  private _initCategory(): void {
    this.getLastSolutions().then((res) => {
      this.category = res;

      this._htmlService.setViewTitle(
        this.category.title,
        environment.SITE_NAME,
      );
    });
  }
}

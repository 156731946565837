<ng-container>
  @if (link) {
    <a
      [class]="'item-content'"
      [routerLink]="link"
      [routerLinkActive]="'active'"
    >
      {{ title }}
    </a>
  } @else {
    <span
      [class]="'item-content cursor-context-menu'"
    >
      {{ title }}
    </span>
  }

  @if (items && items.length) {
    <button class="arrow-button" (click)="toggleVisibleClick()" (mouseenter)="toggleVisibleHover(true)">
      <fa-icon [icon]="getArrowIcon()"/>
    </button>

    <div
      class="sub-items"
      [ngClass]="{
        'items-visible': visible,
        'items-hidden': !visible,
      }"
      (mouseleave)="toggleVisibleHover(false)"
    >
      <div class="back-container">
        <button class="back-button" (click)="toggleVisibleClick()">
          <fa-icon [icon]="icons.arrowBack"/>
        </button>
      </div>

      <div class="sub-items-content">
        @for (item of items; track $index) {
          <a
            [title]="item.title"
            [routerLink]="item.link"
            [routerLinkActive]="'active'"
            class="sub-item"
          >
            {{ item.title }}
          </a>
        }
      </div>
    </div>
  }
</ng-container>

import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { HeadlineComponent } from '../../../../components/headline/headline.component';
import { PlatformIdService, when } from '@ckmk/angular';

@Component({
  selector: 'front-section',
  standalone: true,
  imports: [HeadlineComponent],
  templateUrl: './front-section.component.html',
  styleUrl: './front-section.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FrontSectionComponent implements OnInit, OnChanges {
  @Input({
    required: false,
  })
  public title?: string;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _platformIdService: PlatformIdService,
  ) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges): void {}

  protected getHeadlineLevel(): number {
    return when(
      this._platformIdService.isBrowser() &&
        document.body.getBoundingClientRect().width <= 768,
      1,
      4,
    );
  }
}

import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FrontContentComponent } from '../../../../components/front-content/front-content.component';
import { FrontSectionComponent } from '../../../../components/front-section/front-section.component';
import { FrontHeaderComponent } from '../../../../components/front-header/front-header.component';
import { HeadlineComponent } from '../../../../../../components/headline/headline.component';

@Component({
  selector: 'about',
  standalone: true,
  imports: [
    FrontContentComponent,
    FrontSectionComponent,
    FrontHeaderComponent,
    HeadlineComponent,
  ],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AboutComponent implements OnInit, OnChanges {
  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges): void {}
}

import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { RemoteMenuItems } from '../../../../types/types';
import {
  faArrowLeft,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  FaIconComponent,
  IconDefinition,
} from '@fortawesome/angular-fontawesome';
import { PlatformIdService, when } from '@ckmk/angular';
import { NgClass } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'menu-item-group',
  standalone: true,
  imports: [FaIconComponent, NgClass, RouterLink, RouterLinkActive],
  templateUrl: './menu-item-group.component.html',
  styleUrl: './menu-item-group.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class MenuItemGroupComponent implements OnInit, OnChanges {
  @HostBinding('title')
  @Input({ required: true })
  public title!: string;

  @Input({ required: false })
  public link?: string;

  @Input({ required: false })
  public items?: RemoteMenuItems;

  protected icons = {
    arrowRight: faChevronRight,
    arrowDown: faChevronDown,
    arrowBack: faArrowLeft,
  };

  protected visible: boolean = false;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _platformIdService: PlatformIdService,
  ) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {}

  protected getArrowIcon(): IconDefinition {
    return when(
      this._isPhoneDevice(),
      this.icons.arrowRight,
      this.icons.arrowDown,
    );
  }

  protected toggleVisibleClick(): void {
    if (this._isPhoneDevice()) {
      this.visible = !this.visible;
    }
  }

  protected toggleVisibleHover(value: boolean): void {
    if (!this._isPhoneDevice()) {
      this.visible = value;
    }
  }

  private _isPhoneDevice(): boolean {
    return (
      this._platformIdService.isBrowser() &&
      document.body.getBoundingClientRect().width < 768
    );
  }
}

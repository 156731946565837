import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FrontContentComponent } from '../../../../components/front-content/front-content.component';
import { FrontSectionComponent } from '../../../../components/front-section/front-section.component';
import { FrontHeaderComponent } from '../../../../components/front-header/front-header.component';

@Component({
  selector: 'rse',
  standalone: true,
  imports: [FrontContentComponent, FrontSectionComponent, FrontHeaderComponent],
  templateUrl: './rse.component.html',
  styleUrl: './rse.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class RseComponent implements OnInit, OnChanges {
  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges): void {}
}

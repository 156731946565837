import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FrontContentComponent } from '../../../../components/front-content/front-content.component';
import { FrontSectionComponent } from '../../../../components/front-section/front-section.component';
import { ContactType } from '../../classes/form/ContactType';
import { FormBuilderModule } from '@ckmk/angular';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FrontHeaderComponent } from '../../../../components/front-header/front-header.component';

@Component({
  selector: 'contact',
  standalone: true,
  imports: [
    FrontContentComponent,
    FrontSectionComponent,
    FormBuilderModule,
    FrontHeaderComponent,
  ],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ContactComponent implements OnInit, OnChanges {
  protected icons = {
    send: faPaperPlane,
  };

  public constructor(
    private readonly _self: ElementRef,
    protected readonly formType: ContactType,
  ) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges): void {}
}

import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'headline',
  standalone: true,
  imports: [],
  templateUrl: './headline.component.html',
  styleUrl: './headline.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HeadlineComponent implements OnInit, OnChanges {
  @Input({ required: false })
  public level: number = 1;

  @Input({ required: false })
  public title?: string;

  @Input({ required: false })
  public label?: string;

  @HostBinding('title')
  protected containerTitle?: string;

  @HostBinding('className')
  protected levelClassName?: string;

  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {
    this.containerTitle = this.title || this.label;
    this.levelClassName = `headline-${this.level}`;
  }

  public ngOnChanges({ title, label, level }: SimpleChanges): void {
    if (title || label) {
      if (title && !title.firstChange) {
        this.containerTitle = title.currentValue;
      } else if (label && !label.firstChange) {
        this.containerTitle = label.currentValue;
      }
    }

    if (level && !level.firstChange) {
      this.levelClassName = `headline-${level.currentValue}`;
    }
  }
}

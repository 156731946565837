<ng-container>
  <div class="container-header">
    <div class="header-title">
      <headline
        [title]="title"
        [label]="title"
      />
    </div>
  </div>
</ng-container>

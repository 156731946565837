<ng-container>
  <front-content>
    <front-header
      [title]="'Services'"
    />

    <front-section>
      Services service
    </front-section>


  </front-content>
</ng-container>

import { Injectable } from '@angular/core';
import {
  FieldTypesConfigList,
  FormBuilderInterface,
  FormTypeInterface,
} from '@ckmk/angular';
import { environment } from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactType implements FormTypeInterface {
  public fields: FieldTypesConfigList = [];

  public configure(builder: FormBuilderInterface): void {
    builder
      .add({
        type: 'default',
        name: 'full_name',
        label: 'Nom complet',
        required: true,
        config: {
          type: 'text',
        },
      })
      .add({
        type: 'default',
        name: 'email',
        label: 'Adresse e-mail',
        required: true,
        config: {
          type: 'email',
        },
      })
      .add({
        type: 'default',
        name: 'subject',
        label: 'Sujet',
        required: true,
        config: {
          type: 'text',
        },
      })
      .add({
        type: 'text',
        name: 'message',
        label: 'Votre message',
        required: true,
        config: {
          type: 'textarea',
        },
      })
      .add({
        type: 'recaptcha',
        name: 'reCaptcha',
        label: 'Le reCaptcha',
        required: true,
        config: {
          siteKey: environment.GOOGLE_RECAPTCHA_PUBLIC_KEY,
        },
      });
  }
}

import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FrontContentComponent } from '../../../../components/front-content/front-content.component';
import { FrontSectionComponent } from '../../../../components/front-section/front-section.component';
import { FrontHeaderComponent } from '../../../../components/front-header/front-header.component';
import { getDevLastNews } from '../../../../../../utils/dev.utils';
import { NewHomeCardComponent } from '../../components/new-home-card/new-home-card.component';

@Component({
  selector: 'news',
  standalone: true,
  imports: [
    FrontContentComponent,
    FrontSectionComponent,
    FrontHeaderComponent,
    NewHomeCardComponent,
  ],
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class NewsComponent implements OnInit, OnChanges {
  protected news!: any[];

  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {
    this.getLastNews().then((res) => (this.news = res));
  }

  public ngOnChanges(changes: SimpleChanges): void {}

  protected getLastNews(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(getDevLastNews(12));
    });
  }
}

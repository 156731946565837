import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { HtmlMetaService } from '@ckmk/angular';
import { environment } from '../../../../../../../environments/environment';
import { getDevLastSolutions } from '../../../../../../utils/dev.utils';
import { FrontHeaderComponent } from '../../../../components/front-header/front-header.component';
import { FrontSectionComponent } from '../../../../components/front-section/front-section.component';

@Component({
  selector: 'solution-show',
  standalone: true,
  imports: [FrontHeaderComponent, FrontSectionComponent],
  templateUrl: './solution-show.component.html',
  styleUrl: './solution-show.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SolutionShowComponent implements OnInit, OnChanges {
  @Input({
    required: true,
    alias: 'category',
  })
  public categorySlug!: string;

  @Input({
    required: true,
    alias: 'solution',
  })
  public solutionSlug!: string;

  protected solution!: any;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _htmlService: HtmlMetaService,
  ) {}

  public ngOnInit() {
    this._initSolution();
  }

  public ngOnChanges({ categorySlug, solutionSlug }: SimpleChanges) {
    if (
      (categorySlug && !categorySlug.firstChange) ||
      (solutionSlug && !solutionSlug.firstChange)
    ) {
      this._initSolution();
    }
  }

  private _getSolution(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      resolve(
        getDevLastSolutions(90).find(
          (it) =>
            it.slug === this.solutionSlug &&
            it.category.slug === this.categorySlug,
        ),
      );
    });
  }

  private _initSolution(): void {
    this._getSolution().then((res) => {
      this.solution = res;

      if (this.solution) {
        this._htmlService.setViewTitle(
          this.solution.title,
          environment.SITE_NAME,
        );
      }
    });
  }
}

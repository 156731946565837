import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { HeadlineComponent } from '../../../../components/headline/headline.component';
import { BtnComponent } from '../../../../components/btn/btn.component';
import { FrontHeaderComponent } from '../front-header/front-header.component';
import { HtmlMetaService } from '@ckmk/angular';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'front-content',
  standalone: true,
  imports: [HeadlineComponent, BtnComponent],
  templateUrl: './front-content.component.html',
  styleUrl: './front-content.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FrontContentComponent
  implements OnInit, OnChanges, AfterContentInit
{
  @ContentChildren(FrontHeaderComponent, {
    descendants: true,
  })
  public headers!: QueryList<FrontHeaderComponent>;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _htmlService: HtmlMetaService,
  ) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges): void {}

  public ngAfterContentInit() {
    Promise.resolve().then(() => {
      if (!this.headers.length) {
        this._htmlService.setViewTitle(
          'Welcome',
          `${environment.SITE_NAME} | `,
        );
      }
    });
  }
}

<ng-container>
  <front-content>
    <front-header
      [title]="'Solutions'"
    />

    <front-section>
      Solutions service
    </front-section>


  </front-content>
</ng-container>

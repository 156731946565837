import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'btn',
  standalone: true,
  imports: [],
  templateUrl: './btn.component.html',
  styleUrl: './btn.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class BtnComponent implements OnInit, OnChanges {
  @Input({ required: false })
  public title?: string;

  @Input({ required: false })
  public label?: string;

  @HostBinding('title')
  protected containerTitle?: string;

  @Output('onClick')
  public clickEvent: EventEmitter<MouseEvent> = new EventEmitter();

  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {
    this.containerTitle = this.title || this.label;
  }

  public ngOnChanges({ title, label, level }: SimpleChanges): void {
    if (title || label) {
      if (title && !title.firstChange) {
        this.containerTitle = title.currentValue;
      } else if (label && !label.firstChange) {
        this.containerTitle = label.currentValue;
      }
    }
  }

  protected onClick(event: MouseEvent): void {
    event.preventDefault();

    this.clickEvent.emit(event);
  }
}

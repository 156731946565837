import { DashboardRoutes } from '@ckmk/angular';
import { environment } from '../../../environments/environment';
import { LoginComponent } from '../../modules/admin/views/login/login.component';
import { DashboardComponent } from '../../modules/admin/views/dashboard/dashboard.component';

export default DashboardRoutes({
  hash: environment.ADMIN_HASH,
  loginComponent: LoginComponent,
  dashboardComponent: DashboardComponent,
});

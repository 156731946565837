<ng-container>
  <img
    [src]="item.imageUrl"
    [alt]="item.title"
    class="card-home-item-img"
  />

  <div class="card-home-item-title">
    {{ item.title }}
  </div>

  <div class="card-home-item-description">
    {{ item.homeDescription }}
  </div>

  <div class="card-home-item-see-more">
    <a
      [routerLink]="['/solutions', item.category.slug, item.slug]"
    >
      <span>
        Voir plus
      </span>

      <fa-icon [icon]="icons.seeMore"/>
    </a>
  </div>

  <div class="card-home-item-headband"></div>
</ng-container>
